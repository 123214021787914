export const GET_INVESTORS = 'GET_INVESTORS'
export const GET_INVESTORS_SUCCESS = 'GET_INVESTORS_SUCCESS'
export const GET_INVESTORS_FAIL = 'GET_INVESTORS_FAIL'
export const GET_INVESTOR = 'GET_INVESTOR'
export const GET_INVESTOR_SUCCESS = 'GET_INVESTOR_SUCCESS'
export const GET_INVESTOR_FAIL = 'GET_INVESTOR_FAIL'
export const CREATE_INVESTOR = 'CREATE_INVESTOR'
export const CREATE_INVESTOR_SUCCESS = 'CREATE_INVESTOR_SUCCESS'
export const CREATE_INVESTOR_FAIL = 'CREATE_INVESTOR_FAIL'
export const UPDATE_INVESTOR = 'UPDATE_INVESTOR'
export const UPDATE_INVESTOR_SUCCESS = 'UPDATE_INVESTOR_SUCCESS'
export const UPDATE_INVESTOR_FAIL = 'UPDATE_INVESTOR_FAIL'
export const DELETE_INVESTOR = 'DELETE_INVESTOR'
export const DELETE_INVESTOR_SUCCESS = 'DELETE_INVESTOR_SUCCESS'
export const DELETE_INVESTOR_FAIL = 'DELETE_INVESTOR_FAIL'

export function getInvestors () {
  return {
    type: GET_INVESTORS
  }
}

export function getInvestor (payload) {
  return {
    type: GET_INVESTOR,
    payload
  }
}

export function createInvestor (payload) {
  return {
    type: CREATE_INVESTOR,
    payload
  }
}

export function updateInvestor (payload) {
  return {
    type: UPDATE_INVESTOR,
    payload
  }
}

export function deleteInvestor (payload) {
  return {
    type: DELETE_INVESTOR,
    payload
  }
}
