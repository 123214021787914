import produce from 'immer';

import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export const defaultState = {
  entities: [],
  entity: null,
  isLoading: false,
  isLoaded: false,
  message: null,
};

const journalists = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_JOURNALISTS:
      case actions.GET_JOURNALIST:
      case actions.CREATE_JOURNALIST:
      case actions.UPDATE_JOURNALIST:
      case actions.DELETE_JOURNALIST:
        draft.isLoading = true;
        draft.isLoaded = false;
        break;

      case actions.GET_JOURNALISTS_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entities = action.entities;
        draft.message = action.message;
        break;

      case actions.GET_JOURNALIST_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.message = action.message;
        break;

      case actions.CREATE_JOURNALIST_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = { [action.entity.id]: action.entity };
        draft.entities = [action.entity, ...state.entities];
        draft.message = action.message;
        break;

      case actions.UPDATE_JOURNALIST_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.entities = [
          action.entity,
          ...state.entities.filter((entity) => entity.id !== action.entity.id),
        ];
        draft.message = action.message;
        break;

      case actions.DELETE_JOURNALIST_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.message = action.message;
        draft.entity = null;
        draft.entities = [
          ...state.entities.filter((entity) => entity.id !== action.id),
        ];
        break;

      case actions.GET_JOURNALISTS_FAIL:
      case actions.GET_JOURNALIST_FAIL:
      case actions.CREATE_JOURNALIST_FAIL:
      case actions.UPDATE_JOURNALIST_FAIL:
      case actions.DELETE_JOURNALIST_FAIL:
        draft.isLoading = false;
        draft.isLoaded = false;
        draft.message = action.message;
        break;

      case sharedActions.CLEAR_MESSAGES:
        draft.message = null;
        break;

      default:
        return draft;
    }
  });

export default journalists;
