import produce from 'immer';

import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export const defaultState = {
  entities: [],
  entity: null,
  isLoading: false,
  isLoaded: false,
  message: null,
};

const directories = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_DIRECTORIES:
      case actions.GET_DIRECTORY:
      case actions.CREATE_DIRECTORY:
      case actions.UPDATE_DIRECTORY:
      case actions.DELETE_DIRECTORY:
        draft.isLoading = true;
        draft.isLoaded = false;
        break;

      case actions.GET_DIRECTORIES_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entities = action.entities;
        draft.message = action.message;
        break;

      case actions.GET_DIRECTORY_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.message = action.message;
        break;

      case actions.CREATE_DIRECTORY_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = { [action.entity.id]: action.entity };
        draft.entities = [action.entity, ...state.entities];
        draft.message = action.message;
        break;

      case actions.UPDATE_DIRECTORY_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.entities = [
          action.entity,
          ...state.entities.filter((entity) => entity.id !== action.entity.id),
        ];
        draft.message = action.message;
        break;

      case actions.DELETE_DIRECTORY_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.message = action.message;
        draft.entity = null;
        draft.entities = [
          ...state.entities.filter((entity) => entity.id !== action.id),
        ];
        break;

      case actions.GET_DIRECTORIES_FAIL:
      case actions.GET_DIRECTORY_FAIL:
      case actions.CREATE_DIRECTORY_FAIL:
      case actions.UPDATE_DIRECTORY_FAIL:
      case actions.DELETE_DIRECTORY_FAIL:
        draft.isLoading = false;
        draft.isLoaded = false;
        draft.message = action.message;
        break;

      case sharedActions.CLEAR_MESSAGES:
        draft.message = null;
        break;

      default:
        return draft;
    }
  });

export default directories;
