/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as firebase from 'firebase/app';
import { auth, firebaseConfig, db } from './config/firebase';

import Main from '@bit/jakeprins.react-milkshake.main';
import Content from '@bit/jakeprins.react-milkshake.content';
import { getCurrentUser, updateUserData } from './features/auth/actions';
import Routes from './routes';
import ModalContainer from './components/Modal/ModalContainer';
import SideBar from './components/SideBar/SideBar';

if (!firebase.apps.length) {
  console.log('initialize firebase');
  firebase.initializeApp(firebaseConfig);
}

const App = ({ isLoading, currentUser, getCurrentUser, updateUserData }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  // useEffect(() => {
  //   if (!window.GA_INITIALIZED) {
  //     initGA();
  //     window.GA_INITIALIZED = true;
  //   }
  //   logPageView();
  // }, []);gst

  // const [gaInitialized, setGaInitialized] = useState(false);

  // if (!gaInitialized) {
  //   ReactGA.initialize('UA-154197045-1', { debug: true });
  //   setGaInitialized(true);
  // }

  // usePageViews();
  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => {
        if (user && !isLoading && !currentUser.username) {
          getCurrentUser({ user });
        }
      },
      (error) => console.log(error)
    );
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (currentUser.uid && !isSubscribed) {
      unsubscribe = db
        .collection('users')
        .doc(currentUser.uid)
        .onSnapshot(function (doc) {
          if (!doc.metadata.hasPendingWrites) {
            console.log('Current user data: ', doc.data());
            updateUserData({ entity: { user: doc.data() } });
          }
        });

      setIsSubscribed(true);
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return (
    <Main>
      <SideBar />
      <Content>
        <Routes />
      </Content>
      <ModalContainer />
    </Main>
  );
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.auth.entity,
    isLoading: state.auth.isLoading,
  };
};

export default connect(mapStateToProps, {
  getCurrentUser,
  updateUserData,
})(App);
