import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as notificationActions from '../notifications/actions';
import * as actions from './actions';
import * as services from './services';
import { stringToSlug } from '../../helpers';

function* signUpSaga(action) {
  try {
    const { payload } = action;

    // Sign up in firebase
    const response = yield call(services.signUp, payload);
    const username = stringToSlug(payload.username);
    const entity = {
      ...response.user,
      username,
    };

    // Create user in db
    yield call(services.createUser, entity);

    if (!entity.emailVerified) {
      yield call(services.sendEmailVerification);
    }

    yield put(push(`/account`));

    yield put({
      type: actions.SIGN_UP_SUCCESS,
      entity,
    });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully signed up!',
      },
    });
  } catch (error) {
    yield put({ type: actions.SIGN_UP_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Signing up failed!',
        subText: error.message,
      },
    });
  }
}

function* signInSaga(action) {
  try {
    const response = yield call(services.signIn, action.payload);
    yield put({
      type: actions.SIGN_IN_SUCCESS,
      entity: response.user,
    });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully signed in!',
        deleteAfter: 3000,
      },
    });
    yield put(push(`/account`));
  } catch (error) {
    yield put({ type: actions.SIGN_IN_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Signing in failed!',
        subText: error.message,
      },
    });
  }
}

function* signOutSaga() {
  try {
    yield call(services.signOut);
    yield put({
      type: actions.SIGN_OUT_SUCCESS,
      entity: {},
    });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully signed out!',
        deleteAfter: 3000,
      },
    });
    yield put(push('/'));
  } catch (error) {
    yield put({ type: actions.SIGN_OUT_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Signing out failed!',
        subText: error.message,
      },
    });
  }
}

function* sendEmailVerificationSaga() {
  try {
    yield call(services.sendEmailVerification);
    yield put({ type: actions.SEND_EMAIL_VERIFICATION_SUCCESS });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully send email verification mail!',
        subText: 'Check your email to confirm your account.',
      },
    });
  } catch (error) {
    yield put({ type: actions.SEND_EMAIL_VERIFICATION_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Sending mail failed.',
        subText: error.message,
      },
    });
  }
}

function* sendResetPasswordMailSaga(action) {
  try {
    yield call(services.sendPasswordResetEmail, action.payload);
    yield put({ type: actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully send password reset mail!',
        subText: 'Check your email for instructions.',
      },
    });
  } catch (error) {
    yield put({ type: actions.SEND_PASSWORD_RESET_EMAIL_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Sending reset password mail failed!',
        subText: error.message,
      },
    });
  }
}

function* confirmPasswordResetSaga(action) {
  try {
    yield call(services.confirmPasswordReset, action.payload);
    yield put({ type: actions.CONFIRM_PASSWORD_RESET_SUCCESS });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully confirmed password reset!',
        deleteAfter: 3000,
      },
    });
  } catch (error) {
    yield put({ type: actions.CONFIRM_PASSWORD_RESET_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Confirm password reset failed!',
        subText: error.message,
      },
    });
  }
}

function* getCurrentUserSaga(action) {
  try {
    const doc = yield call(services.getUser, action.payload);
    const { user } = action.payload;
    const authUserData = {
      refreshToken: user.refreshToken,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber,
      isAnonymous: user.isAnonymous,
    };
    const entity = { id: doc.id, ...doc.data(), ...authUserData };
    yield put({
      type: actions.GET_CURRENT_USER_SUCCESS,
      entity,
    });
  } catch (error) {
    yield put({ type: actions.GET_CURRENT_USER_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Loading user data failed!',
        subText: error.message,
      },
    });
  }
}

function* updateCurrentUserSaga(action) {
  try {
    yield call(services.updateUser, action.payload);
    yield put({
      type: actions.UPDATE_CURRENT_USER_SUCCESS,
      entity: action.payload,
    });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'success',
        text: 'Successfully updated your profile!',
        deleteAfter: 3000,
      },
    });
    yield put(push(`/profile`));
  } catch (error) {
    yield put({ type: actions.UPDATE_CURRENT_USER_FAIL });
    yield put({
      type: notificationActions.CREATE_NOTIFICATION,
      payload: {
        type: 'error',
        text: 'Updating profile failed!',
        subText: error.message,
      },
    });
  }
}

export function* authSaga() {
  yield takeLatest(actions.SIGN_UP, signUpSaga);
  yield takeLatest(actions.SIGN_IN, signInSaga);
  yield takeLatest(actions.SIGN_OUT, signOutSaga);
  yield takeLatest(
    actions.SEND_PASSWORD_RESET_EMAIL,
    sendResetPasswordMailSaga
  );
  yield takeLatest(actions.CONFIRM_PASSWORD_RESET, confirmPasswordResetSaga);
  yield takeLatest(actions.GET_CURRENT_USER, getCurrentUserSaga);
  yield takeLatest(actions.UPDATE_CURRENT_USER, updateCurrentUserSaga);
  yield takeLatest(actions.SEND_EMAIL_VERIFICATION, sendEmailVerificationSaga);
}
