export const GET_BOOKS = 'GET_BOOKS';
export const GET_BOOKS_SUCCESS = 'GET_BOOKS_SUCCESS';
export const GET_BOOKS_FAIL = 'GET_BOOKS_FAIL';
export const SEARCH_BOOKS = 'SEARCH_BOOKS';
export const SEARCH_BOOKS_SUCCESS = 'SEARCH_BOOKS_SUCCESS';
export const SEARCH_BOOKS_FAIL = 'SEARCH_BOOKS_FAIL';

export function getBooks(payload) {
  return {
    type: GET_BOOKS,
    payload
  };
}

export function searchBooks(payload) {
  return {
    type: SEARCH_BOOKS,
    payload
  };
}
