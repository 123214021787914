import * as actions from './actions';

const defaultState = {
  sideBarVisible: false
};

function settings(state = defaultState, { type, payload }) {
  switch (type) {
    case actions.SET_SIDEBAR:
      return {
        ...state,
        sideBarVisible: payload.isVisible
      };

    default:
      return state;
  }
}

export default settings;
