import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getDirectoriesSaga() {
  try {
    const querySnapshot = yield call(services.getDirectories);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_DIRECTORIES_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_DIRECTORIES_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createDirectoriesSaga(action) {
  try {
    const doc = yield call(services.createDirectory, action.payload);
    const entity = { ...action.payload };
    yield put({
      type: actions.CREATE_DIRECTORY_SUCCESS,
      entity,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/directories'));
  } catch (error) {
    yield put({
      type: actions.CREATE_DIRECTORY_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updateDirectoriesSaga(action) {
  try {
    yield call(services.updateDirectory, action.payload);
    yield put({
      type: actions.UPDATE_DIRECTORY_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/directories'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_DIRECTORY_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deleteDirectoriesSaga(action) {
  try {
    yield call(services.deleteDirectory, action.payload);
    yield put(push('/directories'));
    yield put({
      type: actions.DELETE_DIRECTORY_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_DIRECTORY_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* directoriesSaga() {
  yield takeLatest(actions.GET_DIRECTORIES, getDirectoriesSaga);
  yield takeLatest(actions.CREATE_DIRECTORY, createDirectoriesSaga);
  yield takeLatest(actions.UPDATE_DIRECTORY, updateDirectoriesSaga);
  yield takeLatest(actions.DELETE_DIRECTORY, deleteDirectoriesSaga);
}
