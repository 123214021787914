import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getToolsSaga() {
  try {
    const querySnapshot = yield call(services.getTools);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_TOOLS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_TOOLS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* getFeaturedToolsSaga() {
  try {
    const querySnapshot = yield call(services.getFeaturedTools);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_FEATURED_TOOLS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_FEATURED_TOOLS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createToolSaga(action) {
  try {
    const doc = yield call(services.createTool, action.payload);
    yield put({
      type: actions.CREATE_TOOL_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/tools'));
  } catch (error) {
    yield put({
      type: actions.CREATE_TOOL_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updateToolSaga(action) {
  try {
    yield call(services.updateTool, action.payload);
    yield put({
      type: actions.UPDATE_TOOL_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/tools'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_TOOL_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deleteToolSaga(action) {
  try {
    yield call(services.deleteTool, action.payload);
    yield put(push('/tools'));
    yield put({
      type: actions.DELETE_TOOL_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_TOOL_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* toolsSaga() {
  yield takeLatest(actions.GET_TOOLS, getToolsSaga);
  yield takeLatest(actions.GET_FEATURED_TOOLS, getFeaturedToolsSaga);
  yield takeLatest(actions.CREATE_TOOL, createToolSaga);
  yield takeLatest(actions.UPDATE_TOOL, updateToolSaga);
  yield takeLatest(actions.DELETE_TOOL, deleteToolSaga);
}
