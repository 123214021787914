export const GET_PROMOTIONS = 'GET_PROMOTIONS'
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS'
export const GET_PROMOTIONS_FAIL = 'GET_PROMOTIONS_FAIL'
export const GET_PROMOTION = 'GET_PROMOTION'
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS'
export const GET_PROMOTION_FAIL = 'GET_PROMOTION_FAIL'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS'
export const CREATE_PROMOTION_FAIL = 'CREATE_PROMOTION_FAIL'
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION'
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS'
export const UPDATE_PROMOTION_FAIL = 'UPDATE_PROMOTION_FAIL'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS'
export const DELETE_PROMOTION_FAIL = 'DELETE_PROMOTION_FAIL'

export function getPromotions () {
  return {
    type: GET_PROMOTIONS
  }
}

export function getPromotion (payload) {
  return {
    type: GET_PROMOTION,
    payload
  }
}

export function createPromotion (payload) {
  return {
    type: CREATE_PROMOTION,
    payload
  }
}

export function updatePromotion (payload) {
  return {
    type: UPDATE_PROMOTION,
    payload
  }
}

export function deletePromotion (payload) {
  return {
    type: DELETE_PROMOTION,
    payload
  }
}
