import { auth, db, now } from '../../config/firebase';

export const getInvestors = () => {
  return db.collection('investors').get();
};

export const getInvestor = (payload) => {
  return db.collection('investors').doc(payload.id).get();
};

export const createInvestor = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  payload.userId = auth.currentUser.uid;
  return db.collection('investors').doc(payload.id).set(payload);
};

export const updateInvestor = (payload) => {
  payload.updatedAt = now;
  return db.collection('investors').doc(payload.id).update(payload);
};

export const deleteInvestor = (payload) => {
  return db.collection('investors').doc(payload.id).delete();
};
