export const categories = [
  { value: 'Accounting/invoicing', label: 'Accounting/invoicing' },
  { value: 'API Builder', label: 'API Builder' },
  { value: 'App Developer Tools', label: 'App Developer Tools' },
  {
    value: 'Application Distribution',
    label: 'Application Distribution',
  },
  { value: 'Application Performance', label: 'Application Performance' },
  { value: 'Artifical Intelligence', label: 'Artifical Intelligence' },
  { value: 'AWS Tools', label: 'AWS Tools' },
  { value: 'Backend-aaS', label: 'Backend-aaS' },
  { value: 'Banking', label: 'Banking' },
  {
    value: 'Billing & Payment Processing',
    label: 'Billing & Payment Processing',
  },
  { value: 'BlockChain', label: 'BlockChain' },
  { value: 'Browser/Email Testing', label: 'Browser/Email Testing' },
  { value: 'Bug/Issue Tracking', label: 'Bug/Issue Tracking' },
  {
    value: 'Business Cards and Print Material',
    label: 'Business Cards and Print Material',
  },
  {
    value: 'Business & Traffic Analytics',
    label: 'Business & Traffic Analytics',
  },
  { value: 'Community Tools', label: 'Community Tools' },
  {
    value: 'Content Creation/Infographics',
    label: 'Content Creation/Infographics',
  },
  {
    value: 'Continuous Integration/Code Quality',
    label: 'Continuous Integration/Code Quality',
  },
  {
    value: 'Conversion Optimization & A/B Testing',
    label: 'Conversion Optimization & A/B Testing',
  },
  { value: 'Crypto', label: 'Crypto' },
  { value: 'CRM/Sales Tools', label: 'CRM/Sales Tools' },
  { value: 'Customer Feedback', label: 'Customer Feedback' },
  {
    value: 'Customer Support/Help Desks',
    label: 'Customer Support/Help Desks',
  },
  { value: 'Dashboards', label: 'Dashboards' },
  { value: 'Data', label: 'Data' },
  { value: 'Database', label: 'Database' },
  { value: 'Database-aaS', label: 'Database-aaS' },
  { value: 'Deployment', label: 'Deployment' },
  { value: 'Design Collaboration', label: 'Design Collaboration' },
  { value: 'Designers', label: 'Designers' },
  { value: 'Digital story telling', label: 'Digital story telling' },
  { value: 'DNS', label: 'DNS' },
  { value: 'Documentation', label: 'Documentation' },
  {
    value: 'Email Collection/Landing Page Apps',
    label: 'Email Collection/Landing Page Apps',
  },
  { value: 'Email Marketing', label: 'Email Marketing' },
  { value: 'Engineering Metrics', label: 'Engineering Metrics' },
  {
    value: 'Error/Exception Handling',
    label: 'Error/Exception Handling',
  },
  { value: 'Fintech', label: 'Fintech' },
  { value: 'Forms / Surveys', label: 'Forms / Surveys' },
  {
    value: 'Fundraising / Investor Relations',
    label: 'Fundraising / Investor Relations',
  },
  { value: 'Geocoding', label: 'Geocoding' },
  {
    value: 'Group Communication/Chat Tools',
    label: 'Group Communication/Chat Tools',
  },
  { value: 'Heroku Tools', label: 'Heroku Tools' },
  { value: 'HR', label: 'HR' },
  { value: 'Identity Verification', label: 'Identity Verification' },
  { value: 'Income Analytics', label: 'Income Analytics' },
  { value: 'Knowledge Tracking/Wiki', label: 'Knowledge Tracking/Wiki' },
  { value: 'Load Testing', label: 'Load Testing' },
  {
    value: 'Localization & Internationalization',
    label: 'Localization & Internationalization',
  },
  { value: 'Log Monitoring', label: 'Log Monitoring' },
  { value: 'Naming', label: 'Naming' },
  { value: 'No-code', label: 'No-code' },
  { value: 'Notes', label: 'Notes' },
  { value: 'Offsite Backups', label: 'Offsite Backups' },
  {
    value: 'Ops Alerts and Scheduling',
    label: 'Ops Alerts and Scheduling',
  },
  { value: 'Other APIs', label: 'Other APIs' },
  { value: 'PaaS', label: 'PaaS' },
  { value: 'Password Management', label: 'Password Management' },
  {
    value: 'Payments, Billing & Downloads',
    label: 'Payments, Billing & Downloads',
  },
  { value: 'Payroll', label: 'Payroll' },
  {
    value: 'Personal Machine Backups',
    label: 'Personal Machine Backups',
  },
  { value: 'Personal Productivity', label: 'Personal Productivity' },
  { value: 'Phone/PBX/SMS', label: 'Phone/PBX/SMS' },
  {
    value: 'Planning & Project Management',
    label: 'Planning & Project Management',
  },
  { value: 'Presentations / Slides', label: 'Presentations / Slides' },
  {
    value: 'Privacy Policy, Terms & Conditions, Legal Documents',
    label: 'Privacy Policy, Terms & Conditions, Legal Documents',
  },
  { value: 'Prototyping/Mockups', label: 'Prototyping/Mockups' },
  { value: 'Remote Collaboration', label: 'Remote Collaboration' },
  { value: 'Remote Workers', label: 'Remote Workers' },
  { value: 'Robotic Process Automation', label: 'Robotic Process Automation' },
  { value: 'Search', label: 'Search' },
  { value: 'Security', label: 'Security' },
  { value: 'SEO Tools', label: 'SEO Tools' },
  { value: 'Server Monitoring', label: 'Server Monitoring' },
  { value: 'Shipping', label: 'Shipping' },
  { value: 'Site Search', label: 'Site Search' },
  { value: 'Social Media Marketing', label: 'Social Media Marketing' },
  { value: 'Source Code Hosting', label: 'Source Code Hosting' },
  {
    value: 'Sources of Clicks/Ad Platforms',
    label: 'Sources of Clicks/Ad Platforms',
  },
  { value: 'Space Rental', label: 'Space Rental' },
  {
    value: 'Status Blogs/User Alerts',
    label: 'Status Blogs/User Alerts',
  },
  { value: 'Storage', label: 'Storage' },
  { value: 'System Monitoring', label: 'System Monitoring' },
  { value: 'Task Scheduling', label: 'Task Scheduling' },
  { value: 'Time Tracking', label: 'Time Tracking' },
  { value: 'Transactional Email', label: 'Transactional Email' },
  { value: 'User Feedback', label: 'User Feedback' },
  { value: 'User Management', label: 'User Management' },
  { value: 'User Testing', label: 'User Testing' },
  { value: 'Video Hosting', label: 'Video Hosting' },
  { value: 'VPS', label: 'VPS' },
  { value: 'WebSockets-aaS', label: 'WebSockets-aaS' },
];
