import React from 'react';

import styled from 'styled-components';

const Container = styled.button`
  cursor: pointer;
  background: #f6f9fc;
  padding: 10px 16px;
  border-radius: 4px;
  // display: inline-flex;
  border: 1px solid #6772e5;
  text-align: center;
  align-items: center;
  transition: all 0.2s ease-out;
  h4 {
    margin: 0;
    color: #6772e5;
  }
  &:hover {
    background: #d0e0ef;
  }
  span {
    font-weight: bold;
    margin-left: 7px;
    color: #6772e5;
  }
`;

const Text = styled.span`
  ${(withIcon) => withIcon && `margin-left: 5px`}
`;

const Button = (props) => {
  return (
    <Container
      onClick={props.onClick}
      className={props.className}
      style={props.styles}
      type={props.type}
      value={props.value}>
      <Text withIcon={props.icon}>
        {props.isLoading ? 'Loading...' : props.title}
      </Text>
    </Container>
  );
};

export default Button;
