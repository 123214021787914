import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from '../features/auth/reducer';
import books from '../features/books/reducer';
import directories from '../features/directories/reducer';
import investors from '../features/investors/reducer';
import journalists from '../features/journalists/reducer';
import modals from '../features/modals/reducer';
import news from '../features/news/reducer';
import podcasts from '../features/podcasts/reducer';
import promotions from '../features/promotions/reducer';
import settings from '../features/settings/reducer';
import strategies from '../features/strategies/reducer';
import links from '../features/links/reducer';
import tools from '../features/tools/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    books,
    directories,
    investors,
    journalists,
    links,
    modals,
    news,
    podcasts,
    promotions,
    settings,
    tools,
  });

export default createRootReducer;
