import { auth, db, now } from '../../config/firebase';

export const getLinks = () => {
  return db.collection('links').get();
};

export const getLink = (payload) => {
  return db.collection('links').doc(payload.id).get();
};

export const createLink = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  payload.userId = auth.currentUser.uid;
  return db.collection('links').doc(payload.id).set(payload);
};

export const updateLink = (payload) => {
  payload.updatedAt = now;
  return db.collection('links').doc(payload.id).update(payload);
};

export const deleteLink = (payload) => {
  return db.collection('links').doc(payload.id).delete();
};
