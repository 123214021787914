import * as actions from './actions';

const defaultState = {
  entities: [],
  isLoading: false,
  isLoaded: false,
  error: null,
  search: {
    entities: [],
    isLoading: false,
    isLoaded: false,
    error: null
  }
};

function books(state = defaultState, { type, entities }) {
  switch (type) {
    case actions.GET_BOOKS:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_BOOKS_SUCCESS:
      return {
        ...state,
        entities,
        isLoading: false,
        isLoaded: true
      };
    case actions.GET_BOOKS_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };

    case actions.SEARCH_BOOKS:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
          isLoaded: false
        }
      };
    case actions.SEARCH_BOOKS_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          entities,
          isLoading: false,
          isLoaded: true
        }
      };
    case actions.SEARCH_BOOKS_FAIL:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          isLoaded: false
        }
      };

    default:
      return state;
  }
}

export default books;
