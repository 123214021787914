import { SHOW_MODAL, HIDE_MODAL } from './actions';

const defaultState = {
  modalType: null,
  modalProps: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case HIDE_MODAL:
      return defaultState;

    default:
      return state;
  }
}
