import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute';
import Loader from './components/Loader/Loader';
import NewTool from './pages/Tools/NewTool';
import EditTool from './pages/Tools/EditTool';

const AsyncHome = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './pages/Home/Home'),
  loading: Loader,
});

export const AsyncBooks = Loadable({
  loader: () => import(/* webpackChunkName: "Books" */ './pages/Books/Books'),
  loading: Loader,
});

export const AsyncDirectories = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Directories" */ './pages/Directories/Directories'
    ),
  loading: Loader,
});

export const NewDirectory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "NewDirectory" */ './pages/Directories/NewDirectory'
    ),
  loading: Loader,
});

export const EditDirectory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EditDirectory" */ './pages/Directories/EditDirectory'
    ),
  loading: Loader,
});

export const AsyncInvestors = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Investors" */ './pages/Investors/InvestorList'
    ),
  loading: Loader,
});

export const NewInvestor = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Investors" */ './pages/Investors/NewInvestor'),
  loading: Loader,
});

export const EditInvestor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Investors" */ './pages/Investors/EditInvestor'
    ),
  loading: Loader,
});

export const AsyncJournalists = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Journalists" */ './pages/Journalists/JournalistList'
    ),
  loading: Loader,
});

export const NewJournalist = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Journalists" */ './pages/Journalists/NewJournalist'
    ),
  loading: Loader,
});

export const EditJournalist = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Journalists" */ './pages/Journalists/EditJournalist'
    ),
  loading: Loader,
});

export const AsyncNews = Loadable({
  loader: () => import(/* webpackChunkName: "News" */ './pages/News/News'),
  loading: Loader,
});

export const AsyncPodcasts = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Podcasts" */ './pages/Podcasts/Podcasts'),
  loading: Loader,
});

export const AsyncPro = Loadable({
  loader: () => import(/* webpackChunkName: "Pro" */ './pages/Pro/Pro'),
  loading: Loader,
});

export const AsyncStrategies = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Strategies" */ './pages/Strategies/Strategies'
    ),
  loading: Loader,
});

export const AsyncLinks = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Links" */ './pages/links/LinkList'),
  loading: Loader,
});

export const NewLink = Loadable({
  loader: () => import(/* webpackChunkName: "Links" */ './pages/links/NewLink'),
  loading: Loader,
});

export const EditLink = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Links" */ './pages/links/EditLink'),
  loading: Loader,
});

export const AsyncPromotions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Promotions" */ './pages/promotions/PromotionList'
    ),
  loading: Loader,
});

export const NewPromotion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Promotions" */ './pages/promotions/NewPromotion'
    ),
  loading: Loader,
});

export const EditPromotion = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Promotions" */ './pages/promotions/EditPromotion'
    ),
  loading: Loader,
});

export const AsyncTools = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Tools" */ './pages/Tools/ToolList'),
  loading: Loader,
});

export const AsyncToolForm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Tools" */ './pages/Tools/ToolForm'),
  loading: Loader,
});

export const AsyncSignIn = Loadable({
  loader: () => import(/* webpackChunkName: "SignIn" */ './pages/Auth/SignIn'),
  loading: Loader,
});

export const AsyncSignUp = Loadable({
  loader: () => import(/* webpackChunkName: "SignUp" */ './pages/Auth/SignUp'),
  loading: Loader,
});

export const AsyncAccount = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Account" */ './pages/Auth/Account'),
  loading: Loader,
});

export const AsyncResetPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Reset Password" */ './pages/Auth/ResetPassword'
    ),
  loading: Loader,
});
export const AsyncPromotionPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Reset Password" */ './pages/promotions/PromotePage'
    ),
  loading: Loader,
});

const Routes = () => {
  return (
    <Switch>
      <Route path='/' exact component={AsyncHome} />
      <Route path='/books' exact component={AsyncBooks} />
      <Route path='/directories' exact component={AsyncDirectories} />
      <Route path='/directories/new' exact component={NewDirectory} />
      <Route path='/directories/:id/edit' exact component={EditDirectory} />
      <Route path='/investors' exact component={AsyncInvestors} />
      <Route path='/investors/new' exact component={NewInvestor} />
      <Route path='/investors/:id/edit' exact component={EditInvestor} />
      <Route path='/journalists' exact component={AsyncJournalists} />
      <Route path='/journalists/new' exact component={NewJournalist} />
      <Route path='/journalists/:id/edit' exact component={EditJournalist} />
      <Route path='/news' exact component={AsyncNews} />
      <Route path='/podcasts' exact component={AsyncPodcasts} />
      <Route path='/pro' exact component={AsyncPro} />
      <Route path='/strategies' exact component={AsyncStrategies} />
      <Route path='/promote' exact component={AsyncPromotionPage} />
      <Route path='/promotions' exact component={AsyncPromotions} />
      <Route path='/promotions/new' exact component={NewPromotion} />
      <Route path='/promotions/:id/edit' exact component={EditPromotion} />
      <Route path='/links' exact component={AsyncLinks} />
      <Route path='/links/new' exact component={NewLink} />
      <Route path='/links/:id/edit' exact component={EditLink} />
      <Route path='/tools' exact component={AsyncTools} />
      <Route path='/tools/new' exact component={NewTool} />
      <Route path='/tools/:id/edit' exact component={EditTool} />
      <Route path='/tools' exact component={AsyncTools} />
      <Route path='/tools/form' exact component={AsyncToolForm} />
      <Route path='/sign_in' exact component={AsyncSignIn} />
      <Route path='/sign_up' exact component={AsyncSignUp} />
      <AuthenticatedRoute path='/account' exact component={AsyncAccount} />
      <Route
        path='/sign_in/reset_password'
        exact
        component={AsyncResetPassword}
      />
    </Switch>
  );
};

export default Routes;
