import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getJournalistsSaga() {
  try {
    const querySnapshot = yield call(services.getJournalists);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_JOURNALISTS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_JOURNALISTS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createJournalistSaga(action) {
  try {
    const doc = yield call(services.createJournalist, action.payload);
    const entity = { ...action.payload };
    yield put({
      type: actions.CREATE_JOURNALIST_SUCCESS,
      entity,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/journalists'));
  } catch (error) {
    yield put({
      type: actions.CREATE_JOURNALIST_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updateJournalistSaga(action) {
  try {
    yield call(services.updateJournalist, action.payload);
    yield put({
      type: actions.UPDATE_JOURNALIST_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/journalists'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_JOURNALIST_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deleteJournalistSaga(action) {
  try {
    yield call(services.deleteJournalist, action.payload);
    yield put(push('/journalists'));
    yield put({
      type: actions.DELETE_JOURNALIST_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_JOURNALIST_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* journalistsSaga() {
  yield takeLatest(actions.GET_JOURNALISTS, getJournalistsSaga);
  yield takeLatest(actions.CREATE_JOURNALIST, createJournalistSaga);
  yield takeLatest(actions.UPDATE_JOURNALIST, updateJournalistSaga);
  yield takeLatest(actions.DELETE_JOURNALIST, deleteJournalistSaga);
}
