import { auth, db, now } from '../../config/firebase';

export const signIn = ({ email, password }) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signUp = ({ email, password }) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const signOut = () => {
  return auth.signOut();
};

export const sendPasswordResetEmail = ({ email }) => {
  return auth.sendPasswordResetEmail(email);
};

export const confirmPasswordReset = ({ code, password }) => {
  return auth.confirmPasswordReset(code, password);
};

export const sendEmailVerification = () => {
  return auth.currentUser.sendEmailVerification();
};

export const getUser = ({ user }) => {
  return db.collection('users').doc(user.uid).get();
};

export const createUser = ({ uid, email, username }) => {
  return db.collection('users').doc(uid).set({
    username,
    email,
    uid,
    createdAt: now,
  });
};

export const updateUser = ({ id, user }) => {
  return db.collection('users').doc(id).update(user);
};
