export const GET_TOOLS = 'GET_TOOLS';
export const GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const GET_TOOLS_FAIL = 'GET_TOOLS_FAIL';
export const GET_FEATURED_TOOLS = 'GET_FEATURED_TOOLS';
export const GET_FEATURED_TOOLS_SUCCESS = 'GET_FEATURED_TOOLS_SUCCESS';
export const GET_FEATURED_TOOLS_FAIL = 'GET_FEATURED_TOOLS_FAIL';
export const GET_TOOL = 'GET_TOOL';
export const GET_TOOL_SUCCESS = 'GET_TOOL_SUCCESS';
export const GET_TOOL_FAIL = 'GET_TOOL_FAIL';
export const CREATE_TOOL = 'CREATE_TOOL';
export const CREATE_TOOL_SUCCESS = 'CREATE_TOOL_SUCCESS';
export const CREATE_TOOL_FAIL = 'CREATE_TOOL_FAIL';
export const UPDATE_TOOL = 'UPDATE_TOOL';
export const UPDATE_TOOL_SUCCESS = 'UPDATE_TOOL_SUCCESS';
export const UPDATE_TOOL_FAIL = 'UPDATE_TOOL_FAIL';
export const DELETE_TOOL = 'DELETE_TOOL';
export const DELETE_TOOL_SUCCESS = 'DELETE_TOOL_SUCCESS';
export const DELETE_TOOL_FAIL = 'DELETE_TOOL_FAIL';

export function getTools() {
  return {
    type: GET_TOOLS,
  };
}

export function getFeaturedTools() {
  return {
    type: GET_FEATURED_TOOLS,
  };
}

export function getTool(payload) {
  return {
    type: GET_TOOL,
    payload,
  };
}

export function createTool(payload) {
  return {
    type: CREATE_TOOL,
    payload,
  };
}

export function updateTool(payload) {
  return {
    type: UPDATE_TOOL,
    payload,
  };
}

export function deleteTool(payload) {
  return {
    type: DELETE_TOOL,
    payload,
  };
}
