import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as podcastService from './services';

function* fetchPodcastsSaga(action) {
  try {
    const response = yield call(podcastService.getPodcasts, action.payload);
    yield put({
      type:
        action.payload.page > 1
          ? actions.GET_MORE_PODCASTS_SUCCESS
          : actions.GET_PODCASTS_SUCCESS,
      payload: response.data
    });
  } catch (e) {
    yield put({ type: actions.GET_PODCASTS_FAIL, message: e.message });
  }
}

function* fetchPodcastDetailsSaga(action) {
  try {
    const podcasts = yield call(
      podcastService.getPodcastDetails,
      action.payload
    );
    yield put({
      type: actions.GET_PODCAST_DETAILS_SUCCESS,
      payload: podcasts.data
    });
  } catch (e) {
    yield put({
      type: actions.GET_PODCAST_DETAILS_FAIL,
      message: e.message
    });
  }
}

function* searchPodcastsSaga(action) {
  try {
    const podcasts = yield call(podcastService.searchPodcasts, action.payload);
    yield put({
      type: actions.SEARCH_PODCASTS_SUCCESS,
      payload: podcasts.data.results
    });
  } catch (e) {
    yield put({ type: actions.SEARCH_PODCASTS_FAIL, message: e.message });
  }
}

function* fetchMoreEpisodesSaga(action) {
  try {
    const podcasts = yield call(podcastService.getMoreEpisodes, action.payload);
    yield put({
      type: actions.GET_MORE_EPISODES_SUCCESS,
      payload: podcasts.data
    });
  } catch (e) {
    yield put({
      type: actions.GET_MORE_EPISODES_FAIL,
      message: e.message
    });
  }
}

export function* podcastsSaga() {
  yield takeLatest(actions.GET_PODCASTS, fetchPodcastsSaga);
  yield takeLatest(actions.GET_MORE_PODCASTS, fetchPodcastsSaga);
  yield takeLatest(actions.GET_PODCAST_DETAILS, fetchPodcastDetailsSaga);
  yield takeLatest(actions.SEARCH_PODCASTS, searchPodcastsSaga);
  yield takeLatest(actions.GET_MORE_EPISODES, fetchMoreEpisodesSaga);
}
