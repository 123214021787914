import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getPromotionsSaga() {
  try {
    const querySnapshot = yield call(services.getPromotions);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_PROMOTIONS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_PROMOTIONS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createPromotionSaga(action) {
  try {
    const doc = yield call(services.createPromotion, action.payload);
    const entity = { ...action.payload };
    yield put({
      type: actions.CREATE_PROMOTION_SUCCESS,
      entity,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/promotions'));
  } catch (error) {
    yield put({
      type: actions.CREATE_PROMOTION_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updatePromotionSaga(action) {
  try {
    yield call(services.updatePromotion, action.payload);
    yield put({
      type: actions.UPDATE_PROMOTION_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/promotions'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_PROMOTION_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deletePromotionSaga(action) {
  try {
    yield call(services.deletePromotion, action.payload);
    yield put(push('/promotions'));
    yield put({
      type: actions.DELETE_PROMOTION_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_PROMOTION_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* promotionsSaga() {
  yield takeLatest(actions.GET_PROMOTIONS, getPromotionsSaga);
  yield takeLatest(actions.CREATE_PROMOTION, createPromotionSaga);
  yield takeLatest(actions.UPDATE_PROMOTION, updatePromotionSaga);
  yield takeLatest(actions.DELETE_PROMOTION, deletePromotionSaga);
}
