import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import rootSaga from './sagas/index';
import createRootReducer from './reducers/index';

export const history = createBrowserHistory();

// create an object for the default dataq
const defaultState = {};
const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['routing'],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];

export const store = createStore(
  persistedReducer,
  defaultState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
