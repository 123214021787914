import produce from 'immer';

import * as actions from './actions';

export const defaultState = {
  entities: [],
  featuredEntities: [],
  entity: null,
  isLoading: false,
  isLoaded: false,
  message: null,
};

const tools = (state = defaultState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_TOOLS:
      case actions.GET_FEATURED_TOOLS:
      case actions.GET_TOOL:
      case actions.CREATE_TOOL:
      case actions.UPDATE_TOOL:
      case actions.DELETE_TOOL:
        draft.isLoading = true;
        draft.isLoaded = false;
        break;

      case actions.GET_TOOLS_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entities = action.entities;
        draft.message = action.message;
        break;

      case actions.GET_TOOL_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.message = action.message;
        break;

      case actions.GET_FEATURED_TOOLS_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.featuredEntities = action.entities;
        break;

      case actions.CREATE_TOOL_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = { ...action.entity };
        draft.entities = [...state.entities, action.entity];
        if (action.entity.status === 'featured') {
          draft.featuredEntities = [...state.featuredEntities, action.entity];
        }
        break;

      case actions.UPDATE_TOOL_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = action.entity;
        draft.entities = [
          action.entity,
          ...state.entities.filter((entity) => entity.id !== action.entity.id),
        ];
        if (
          state.featuredEntities.map((entity) => entity.id === action.entity.id)
            ?.length &&
          action.entity.status === ''
        ) {
          draft.featuredEntities = [
            ...state.featuredEntities.filter(
              (entity) => entity.id !== action.entity.id
            ),
          ];
        }
        if (action.entity.status === 'featured') {
          draft.featuredEntities = [
            action.entity,
            ...state.featuredEntities.filter(
              (entity) => entity.id !== action.entity.id
            ),
          ];
        }
        break;

      case actions.DELETE_TOOL_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.message = action.message;
        draft.entity = null;
        draft.entities = [
          ...state.entities.filter((entity) => entity.id !== action.id),
        ];
        break;

      case actions.GET_TOOLS_FAIL:
      case actions.GET_FEATURED_TOOLS_FAIL:
      case actions.GET_TOOL_FAIL:
      case actions.CREATE_TOOL_FAIL:
      case actions.UPDATE_TOOL_FAIL:
      case actions.DELETE_TOOL_FAIL:
        draft.isLoading = false;
        draft.isLoaded = false;
        draft.message = action.message;
        break;

      default:
        return draft;
    }
  });

export default tools;
