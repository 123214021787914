import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getLinksSaga() {
  try {
    const querySnapshot = yield call(services.getLinks);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_LINKS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_LINKS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createLinkSaga(action) {
  try {
    const doc = yield call(services.createLink, action.payload);
    const entity = { ...action.payload };
    yield put({
      type: actions.CREATE_LINK_SUCCESS,
      entity,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/links'));
  } catch (error) {
    yield put({
      type: actions.CREATE_LINK_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updateLinkSaga(action) {
  try {
    yield call(services.updateLink, action.payload);
    yield put({
      type: actions.UPDATE_LINK_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/links'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_LINK_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deleteLinkSaga(action) {
  try {
    yield call(services.deleteLink, action.payload);
    yield put(push('/links'));
    yield put({
      type: actions.DELETE_LINK_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_LINK_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* linksSaga() {
  yield takeLatest(actions.GET_LINKS, getLinksSaga);
  yield takeLatest(actions.CREATE_LINK, createLinkSaga);
  yield takeLatest(actions.UPDATE_LINK, updateLinkSaga);
  yield takeLatest(actions.DELETE_LINK, deleteLinkSaga);
}
