export const GET_DIRECTORIES = 'GET_DIRECTORIES';
export const GET_DIRECTORIES_SUCCESS = 'GET_DIRECTORIES_SUCCESS';
export const GET_DIRECTORIES_FAIL = 'GET_DIRECTORIES_FAIL';
export const GET_DIRECTORY = 'GET_DIRECTORY';
export const GET_DIRECTORY_SUCCESS = 'GET_DIRECTORY_SUCCESS';
export const GET_DIRECTORY_FAIL = 'GET_DIRECTORY_FAIL';
export const CREATE_DIRECTORY = 'CREATE_DIRECTORY';
export const CREATE_DIRECTORY_SUCCESS = 'CREATE_DIRECTORY_SUCCESS';
export const CREATE_DIRECTORY_FAIL = 'CREATE_DIRECTORY_FAIL';
export const UPDATE_DIRECTORY = 'UPDATE_DIRECTORY';
export const UPDATE_DIRECTORY_SUCCESS = 'UPDATE_DIRECTORY_SUCCESS';
export const UPDATE_DIRECTORY_FAIL = 'UPDATE_DIRECTORY_FAIL';
export const DELETE_DIRECTORY = 'DELETE_DIRECTORY';
export const DELETE_DIRECTORY_SUCCESS = 'DELETE_DIRECTORY_SUCCESS';
export const DELETE_DIRECTORY_FAIL = 'DELETE_DIRECTORY_FAIL';

export function getDirectories() {
  return {
    type: GET_DIRECTORIES,
  };
}

export function getDirectory(payload) {
  return {
    type: GET_DIRECTORY,
    payload,
  };
}

export function createDirectory(payload) {
  return {
    type: CREATE_DIRECTORY,
    payload,
  };
}

export function updateDirectory(payload) {
  return {
    type: UPDATE_DIRECTORY,
    payload,
  };
}

export function deleteDirectory(payload) {
  return {
    type: DELETE_DIRECTORY,
    payload,
  };
}
