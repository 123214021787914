import { auth, db, now } from '../../config/firebase';

export const getPromotions = () => {
  return db.collection('promotions').get();
};

export const getPromotion = (payload) => {
  return db.collection('promotions').doc(payload.id).get();
};

export const createPromotion = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  payload.userId = auth.currentUser.uid;
  return db.collection('promotions').doc(payload.id).set(payload);
};

export const updatePromotion = (payload) => {
  payload.updatedAt = now;
  return db.collection('promotions').doc(payload.id).update(payload);
};

export const deletePromotion = (payload) => {
  return db.collection('promotions').doc(payload.id).delete();
};
