import { auth, db, now } from '../../config/firebase';

export const getDirectories = () => {
  return db.collection('directories').get();
};

export const getDirectory = (payload) => {
  return db.collection('directories').doc(payload.id).get();
};

export const createDirectory = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  payload.userId = auth.currentUser.uid;
  return db.collection('directories').doc(payload.id).set(payload);
};

export const updateDirectory = (payload) => {
  payload.updatedAt = now;
  return db.collection('directories').doc(payload.id).update(payload);
};

export const deleteDirectory = (payload) => {
  return db.collection('directories').doc(payload.id).delete();
};
