import * as actions from './actions';

const defaultState = {
  entities: [],
  isLoading: false,
  isLoaded: false,
  error: null
};

function strategies(state = defaultState, { type, entities }) {
  switch (type) {
    case actions.GET_STRATEGIES:
      return {
        ...state,
        isLoading: true
      };
    case actions.GET_STRATEGIES_SUCCESS:
      return {
        ...state,
        entities,
        isLoading: false,
        isLoaded: true
      };
    case actions.GET_STRATEGIES_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };

    default:
      return state;
  }
}

export default strategies;
