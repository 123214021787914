import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { getBooks, searchBooks } from './services';

function* getBooksSaga(action) {
  try {
    const response = yield call(getBooks, action.payload);
    yield put({
      type: actions.GET_BOOKS_SUCCESS,
      entities: response.data.items
    });
  } catch (error) {
    yield put({
      type: actions.GET_BOOKS_FAIL,
      error: error.message
    });
  }
}

function* searchBooksSaga(action) {
  try {
    const response = yield call(searchBooks, action.payload);
    yield put({
      type: actions.SEARCH_BOOKS_SUCCESS,
      payload: response.data.items
    });
  } catch (e) {
    yield put({ type: actions.SEARCH_BOOKS_FAIL, message: e.message });
  }
}

export function* booksSaga() {
  yield takeLatest(actions.GET_BOOKS, getBooksSaga);
  yield takeLatest(actions.SEARCH_BOOKS, searchBooksSaga);
}
