import React from 'react';
import { connect } from 'react-redux';
import AudioCard from 'audiocard';
import styled from 'styled-components';

import { hideModal } from '../../features/modals/actions';

const PodcastModal = ({ episode, hideModal }) => {
  return (
    <Modal>
      <CloseButton onClick={() => hideModal()}>
        <div>X</div>
      </CloseButton>
      <AudioCard
        className='podcast-modal'
        art={episode.image}
        source={episode.audio}
        skipBackSeconds={15}
        skipForwardSeconds={15}
        preload='auto'
        color='#31383d'
        background='white'
        progressBarBackground='#f8f8f8'
        progressBarCompleteBackground='#31383d'
        autoPlay
      />
    </Modal>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, { hideModal })(PodcastModal);

const CloseButton = styled.div`
  opacity: 0;
  position: absolute;
  right: -5px;
  top: -7px;
  cursor: pointer;
  background: white;
  border-radius: 30px;
  height: 14px;
`;
const Modal = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 101;
  width: 100%;
  left: 0px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  &:hover ${CloseButton} {
    opacity: 1;
  }

  @media (min-width: 450px) {
    width: 330px;
  }
  @media (min-width: 768px) {
    width: 360px;
    left: 207px;
  }
`;
