export const GET_STRATEGIES = 'GET_STRATEGIES';
export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS';
export const GET_STRATEGIES_FAIL = 'GET_STRATEGIES_FAIL';

export function getStrategies(payload) {
  return {
    type: GET_STRATEGIES,
    payload
  };
}
