import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import Button from '../../components/Button/Button';
import PageHeader from '../../components/PageHeader/PageHeader';
import { createTool, updateTool } from '../../features/tools/actions';
import * as Styled from '../../styles/shared';
import { stringToSlug } from '../../helpers';
import * as firebase from 'firebase/app';
import { categories } from './Categories';

const NewTool = ({ currentUser, createTool, updateTool, isLoading, tools }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { register, errors, handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const newTool = {
      id: stringToSlug(data.name),
      category: selectedCategory,
      categoryId: stringToSlug(selectedCategory),
      createdAt: firebase.firestore.Timestamp.now(),
      updatedAt: firebase.firestore.Timestamp.now(),
      userId: firebase.auth().currentUser.uid,
      username: currentUser.username,
      views: 0,
      likes: [currentUser.username],
      likesCount: 1,
      comments: [],
      commentsCount: 0,
      status: 'live',
      ...data,
    };
    // const catTools = tools.find((tool) => tool.category === newTool.category);

    createTool({ id: newTool.id, ...newTool });
  };

  return (
    <>
      <PageHeader title='Submit Tool' />
      <Styled.CenteredContainer>
        <Styled.Card isLoading={isLoading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: 15 }}>
              <Controller
                as={Select}
                options={categories}
                control={control}
                value={selectedCategory}
                placeholder={selectedCategory || 'Select category'}
                onChange={([selected]) => {
                  return setSelectedCategory(selected.value);
                }}
                defaultValue={selectedCategory}
              />
            </div>
            <Styled.Input
              type='text'
              name='name'
              placeholder='Name'
              ref={register({
                required: 'Please enter a name',
              })}
            />
            {errors.name && (
              <Styled.Message>{errors.name.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='description'
              placeholder='Description'
              ref={register({
                required: 'Please enter a description',
              })}
            />
            {errors.description && (
              <Styled.Message>{errors.description.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='pricing'
              placeholder='pricing'
              ref={register()}
            />
            {errors.pricing && (
              <Styled.Message>{errors.pricing.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='link'
              placeholder='link'
              ref={register()}
            />
            {errors.link && (
              <Styled.Message>{errors.link.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='image'
              placeholder='Image URL'
              ref={register()}
            />
            <Button type='submit' value='Submit' title='Make' />
          </form>
        </Styled.Card>
      </Styled.CenteredContainer>
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    tools: state.tools.entities,
    isLoading: state.tools.isLoading,
    currentUser: state.auth.entity,
  };
}

export default connect(mapStateToProps, { createTool, updateTool })(NewTool);
