import axios from 'axios';
import { rootUrl } from '../../config/constants';

const googleBaseUrl = 'https://www.googleapis.com/books/v1';
const apiKey = 'AIzaSyAgP1Ovp-f9LCAzYXmy4bTCCt_xQIh2PPI';

export const getBooks = payload => {
  const url = `${rootUrl}/data/books/${payload.subject}.json`;
  return axios.get(url);
};

export const searchBooks = payload => {
  const url = `${googleBaseUrl}/volumes?q=${payload.query}&maxResults=40&m&langRestrict=en&key=${apiKey}`;
  return axios.get(url);
};
