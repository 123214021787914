export const GET_JOURNALISTS = 'GET_JOURNALISTS'
export const GET_JOURNALISTS_SUCCESS = 'GET_JOURNALISTS_SUCCESS'
export const GET_JOURNALISTS_FAIL = 'GET_JOURNALISTS_FAIL'
export const GET_JOURNALIST = 'GET_JOURNALIST'
export const GET_JOURNALIST_SUCCESS = 'GET_JOURNALIST_SUCCESS'
export const GET_JOURNALIST_FAIL = 'GET_JOURNALIST_FAIL'
export const CREATE_JOURNALIST = 'CREATE_JOURNALIST'
export const CREATE_JOURNALIST_SUCCESS = 'CREATE_JOURNALIST_SUCCESS'
export const CREATE_JOURNALIST_FAIL = 'CREATE_JOURNALIST_FAIL'
export const UPDATE_JOURNALIST = 'UPDATE_JOURNALIST'
export const UPDATE_JOURNALIST_SUCCESS = 'UPDATE_JOURNALIST_SUCCESS'
export const UPDATE_JOURNALIST_FAIL = 'UPDATE_JOURNALIST_FAIL'
export const DELETE_JOURNALIST = 'DELETE_JOURNALIST'
export const DELETE_JOURNALIST_SUCCESS = 'DELETE_JOURNALIST_SUCCESS'
export const DELETE_JOURNALIST_FAIL = 'DELETE_JOURNALIST_FAIL'

export function getJournalists () {
  return {
    type: GET_JOURNALISTS
  }
}

export function getJournalist (payload) {
  return {
    type: GET_JOURNALIST,
    payload
  }
}

export function createJournalist (payload) {
  return {
    type: CREATE_JOURNALIST,
    payload
  }
}

export function updateJournalist (payload) {
  return {
    type: UPDATE_JOURNALIST,
    payload
  }
}

export function deleteJournalist (payload) {
  return {
    type: DELETE_JOURNALIST,
    payload
  }
}
