import produce from 'immer';

import * as actions from './actions';

export const defaultState = {
  entity: {},
  isLoading: false,
  isLoaded: false,
  message: null,
};

export default (state = defaultState, { type, entity, message }) =>
  produce(state, (draft) => {
    switch (type) {
      case actions.SIGN_UP:
      case actions.SIGN_IN:
      case actions.SIGN_OUT:
      case actions.SEND_PASSWORD_RESET_EMAIL:
      case actions.CONFIRM_PASSWORD_RESET:
      case actions.GET_CURRENT_USER:
      case actions.UPDATE_CURRENT_USER:
      case actions.SEND_EMAIL_VERIFICATION:
        draft.isLoading = true;
        draft.isLoaded = false;
        break;

      case actions.SIGN_UP_SUCCESS:
      case actions.SIGN_IN_SUCCESS:
      case actions.SIGN_OUT_SUCCESS:
      case actions.GET_CURRENT_USER_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = entity;
        break;

      case actions.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      case actions.CONFIRM_PASSWORD_RESET_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        break;

      case actions.SEND_EMAIL_VERIFICATION_SUCCESS:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = { ...state.entity, emailVerified: true };
        break;

      case actions.UPDATE_CURRENT_USER_SUCCESS:
      case actions.UPDATE_USER_DATA:
        draft.isLoading = false;
        draft.isLoaded = true;
        draft.entity = { ...state.entity, ...entity.user };
        break;

      case actions.SIGN_UP_FAIL:
      case actions.SIGN_IN_FAIL:
      case actions.SIGN_OUT_FAIL:
      case actions.SEND_PASSWORD_RESET_EMAIL_FAIL:
      case actions.CONFIRM_PASSWORD_RESET_FAIL:
      case actions.GET_CURRENT_USER_FAIL:
      case actions.UPDATE_CURRENT_USER_FAIL:
      case actions.SEND_EMAIL_VERIFICATION_FAIL:
        draft.isLoading = false;
        draft.isLoaded = false;
        break;

      default:
        return draft;
    }
  });
