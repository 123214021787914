import React from 'react';
import * as Styled from './styles';

const Loader = ({ pastDelay, error }) => {
  if (pastDelay) {
    return (
      <Styled.Centered>
        <Styled.Rotating>
          <span role='img' aria-label='cup'>
            🥤
          </span>
        </Styled.Rotating>
      </Styled.Centered>
    );
  } else if (error) {
    return (
      <Styled.Centered>
        Sorry, there was a problem loading the page.
      </Styled.Centered>
    );
  } else {
    return null;
  }
};

export default Loader;
