import { all } from 'redux-saga/effects';

import { authSaga } from '../features/auth/sagas';
import { booksSaga } from '../features/books/sagas';
import { directoriesSaga } from '../features/directories/sagas';
import { investorsSaga } from '../features/investors/sagas';
import { journalistsSaga } from '../features/journalists/sagas';
import { linksSaga } from '../features/links/sagas';
import { newsSaga } from '../features/news/sagas';
import { podcastsSaga } from '../features/podcasts/sagas';
import { promotionsSaga } from '../features/promotions/sagas';
import { strategiesSaga } from '../features/strategies/sagas';
import { toolsSaga } from '../features/tools/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    booksSaga(),
    directoriesSaga(),
    investorsSaga(),
    journalistsSaga(),
    newsSaga(),
    linksSaga(),
    podcastsSaga(),
    promotionsSaga(),
    strategiesSaga(),
    toolsSaga(),
  ]);
}
