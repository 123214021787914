import { isLocalhost } from '../serviceWorker';

export const messageTypes = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const rootUrl = isLocalhost
  ? 'http://localhost:3000'
  : 'https://www.makermove.com';
