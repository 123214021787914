import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ currentUser, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      currentUser?.email ? <Component {...props} /> : <Redirect to='/sign_in' />
    }
  />
);

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.entity,
  };
};

export default connect(mapStateToProps, {})(AuthenticatedRoute);
