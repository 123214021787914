import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyBRKkU_c8ExybpQvGDNQWUu-eb0DMrxH9k',
  authDomain: 'maker-move.firebaseapp.com',
  databaseURL: 'https://maker-move.firebaseio.com',
  projectId: 'maker-move',
  storageBucket: 'maker-move.appspot.com',
  messagingSenderId: '651555110454',
  appId: '1:651555110454:web:2e5eda3af01132b2926297',
  measurementId: 'G-K9Q6W8C5NS',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const app = firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const now = firebase.firestore.Timestamp.now();

console.log(app.name ? 'Firebase Mode Activated!' : 'Firebase not working :(');
