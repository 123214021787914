import { db, now } from '../../config/firebase';

export const getTools = () => {
  return db.collection('tools').get();
};

export const getFeaturedTools = () => {
  return db.collection('tools').where('status', '==', 'featured').get();
};

export const getTool = (payload) => {
  return db.collection('tools').doc(payload.id).get();
};

export const createTool = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  return db.collection('tools').doc(payload.id).set(payload);
};

export const updateTool = (payload) => {
  // @TODO give type create of update
  payload.updatedAt = now;
  return db.collection('tools').doc(payload.id).update(payload);
};

export const deleteTool = (payload) => {
  return db.collection('tools').doc(payload.id).delete();
};
