export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export function createNotification(notification) {
  const payload = { ...notification };
  if (!payload.id) {
    payload.id = new Date().getTime();
  }
  return {
    type: CREATE_NOTIFICATION,
    payload
  };
}

export function deleteNotification(payload) {
  return {
    type: DELETE_NOTIFICATION,
    payload
  };
}

export function clearNotifications(payload) {
  return {
    type: CLEAR_NOTIFICATIONS,
    payload
  };
}
