import React from 'react';
import { connect } from 'react-redux';

import { setSidebar } from '../../features/settings/actions';
import * as Styled from './styles';
import * as fromRoutes from '../../routes';
import { ReactComponent as BookIcon } from '../../assets/icons/book.svg';
import { ReactComponent as DirectoryIcon } from '../../assets/icons/database.svg';
import { ReactComponent as JournalistIcon } from '../../assets/icons/network.svg';
import { ReactComponent as InvestorIcon } from '../../assets/icons/revenue.svg';
import { ReactComponent as NewsIcon } from '../../assets/icons/rss-feed.svg';
import { ReactComponent as PodcastIcon } from '../../assets/icons/headphone.svg';
import { ReactComponent as ProIcon } from '../../assets/icons/rocket.svg';
import { ReactComponent as PromoteIcon } from '../../assets/icons/bullhorn.svg';
import { ReactComponent as StrategyIcon } from '../../assets/icons/stats-up.svg';
import { ReactComponent as ToolIcon } from '../../assets/icons/hammer.svg';

import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { NavLink } from 'react-router-dom';

const SideBar = ({ isVisible, setSidebar, currentUser }) => {
  return (
    <Styled.Wrapper isVisible={isVisible}>
      <Styled.Toggle
        isVisible={isVisible}
        onClick={() => setSidebar({ isVisible: !isVisible })}>
        <Styled.Span isVisible={isVisible} />
        <Styled.Span isVisible={isVisible} />
        <Styled.Span isVisible={isVisible} />
      </Styled.Toggle>
      <NavLink to='/'>
        <Styled.Logo src={require('../../assets/images/logo.png')} />
      </NavLink>
      <Styled.Link
        to='/books'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncBooks.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <BookIcon width={18} />
        <span>Books</span>
      </Styled.Link>
      <Styled.Link
        to='/directories'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncDirectories.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <DirectoryIcon width={18} />
        <span>Directories</span>
      </Styled.Link>
      <Styled.Link
        to='/investors'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncInvestors.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <InvestorIcon width={18} />
        <span>Investors</span>
      </Styled.Link>
      <Styled.Link
        to='/journalists'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncJournalists.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <JournalistIcon width={18} />
        <span>Journalists</span>
      </Styled.Link>
      <Styled.Link
        to='/news'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncNews.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <NewsIcon width={18} />
        <span>News</span>
      </Styled.Link>
      <Styled.Link
        to='/podcasts'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncPodcasts.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <PodcastIcon width={18} />
        <span>Podcasts</span>
      </Styled.Link>
      <Styled.Link
        to='/tools'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncTools.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <ToolIcon width={18} />
        <span>Tools</span>
      </Styled.Link>
      <Styled.Link
        to='/promote'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncPromotionPage.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <PromoteIcon width={18} />
        <span>Promote</span>
      </Styled.Link>
      <Styled.Link
        to='/pro'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncPro.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <ProIcon width={18} />
        <span>Pro</span>
      </Styled.Link>
      <Styled.Link
        to='/links'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncLinks.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <StrategyIcon width={18} />
        <span>Links</span>
      </Styled.Link>
      {/* <Styled.Link
        to='/strategies'
        activeClassName='active'
        onMouseOver={() => fromRoutes.AsyncStrategies.preload()}
        onClick={() => setSidebar({ isVisible: false })}>
        <StrategyIcon width={18} />
        <span>Strategies</span>
      </Styled.Link> */}
      {currentUser?.email ? (
        <Styled.Link
          to='/account'
          activeClassName='active'
          onMouseOver={() => fromRoutes.AsyncAccount.preload()}
          onClick={() => setSidebar({ isVisible: false })}>
          <UserIcon width={18} id='account' />
          <span>Account</span>
        </Styled.Link>
      ) : (
        <>
          {/* <Styled.Link
            to='/sign_in'
            activeClassName='active'
            onMouseOver={() => fromRoutes.AsyncSignIn.preload()}
            onClick={() => setSidebar({ isVisible: false })}>
            <UserIcon width={18} id='signin' />
            <span>Log in</span>
          </Styled.Link>
          <Styled.Link
            to='/sign_up'
            activeClassName='active'
            onMouseOver={() => fromRoutes.AsyncSignUp.preload()}
            onClick={() => setSidebar({ isVisible: false })}>
            <PlusIcon width={18} id='signup' />
            <span>Sign Up</span>
          </Styled.Link> */}
        </>
      )}
    </Styled.Wrapper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    isVisible: state.settings.sideBarVisible,
    currentUser: state.auth.entity,
  };
};

export default connect(mapStateToProps, { setSidebar })(SideBar);
