export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN FAIL';

export function signIn(payload) {
  return {
    type: SIGN_IN,
    payload,
  };
}

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export function signUp(payload) {
  return {
    type: SIGN_UP,
    payload,
  };
}

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL';

export function getCurrentUser(payload) {
  return {
    type: GET_CURRENT_USER,
    payload,
  };
}

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_FAIL = 'SIGN_OUT_FAIL';

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  'SEND_PASSWORD_RESET_EMAIL_SUCCESS';
export const SEND_PASSWORD_RESET_EMAIL_FAIL = 'SEND_PASSWORD_RESET_EMAIL_FAIL';

export function sendPasswordResetEmail(payload) {
  return {
    type: SEND_PASSWORD_RESET_EMAIL,
    payload,
  };
}

export const CONFIRM_PASSWORD_RESET = 'CONFIRM_PASSWORD_RESET';
export const CONFIRM_PASSWORD_RESET_SUCCESS = 'CONFIRM_PASSWORD_RESET_SUCCESS';
export const CONFIRM_PASSWORD_RESET_FAIL = 'CONFIRM_PASSWORD_RESET_FAIL';

export function confirmPasswordReset(payload) {
  return {
    type: CONFIRM_PASSWORD_RESET,
    payload,
  };
}

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAIL = 'UPDATE_CURRENT_USER_FAIL';

export function updateCurrentUser(payload) {
  return {
    type: UPDATE_CURRENT_USER,
    payload,
  };
}

export const UPDATE_USER_DATA = 'UPDATE_CURRENT_USER_DATA';

export function updateUserData(payload) {
  return {
    type: UPDATE_USER_DATA,
    entity: payload.entity,
  };
}

export const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION';
export const SEND_EMAIL_VERIFICATION_SUCCESS =
  'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAIL = 'SEND_EMAIL_VERIFICATION_FAIL';

export function sendEmailVerification() {
  return {
    type: SEND_EMAIL_VERIFICATION,
  };
}
