import styled, { css } from 'styled-components';

export const Image = styled.img`
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  width: 60px;
  @media (min-width: 450px) {
    width: 120px;
  }
`;

export const TextContainer = styled.div`
  display: inline-block;
  width: calc(100% - 75px);
  vertical-align: top;
  margin-left: 15px;
  @media (min-width: 450px) {
    width: calc(100% - 135px);
  }
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Text = styled.p`
  margin-top: 5px;
  p:first-of-type {
    margin-top: 0px;
  }
`;

export const SelectContainer = styled.div`
  margin: 50px 50px 0px;
  width: 200px;
  @media (max-width: 450px) {
    margin: 50px 25px 0;
  }
`;

export const TopButtonContainer = styled.div`
  margin: 10px 50px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 750px) {
    margin: 0px 30px;
  }

  @media (max-width: 450px) {
    margin: 15px 25px 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 35px;
  margin: 35px 50px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 30px;
    margin: 30px 50px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 25px;
    margin: 25px 50px;
  }

  ${({ spaced }) =>
    spaced &&
    `margin: 60px 50px;
   `}

  @media (max-width: 1024px) {
    margin: 20px 30px;
    ${({ spaced }) => spaced && `margin: 40px 20px;`}
  }

  @media (max-width: 450px) {
    margin: 20px;
    ${({ spaced }) => spaced && `margin: 40px 20px;`}
  }
`;

export const Card = styled.div`
  background-color: #fff;
  background: #fff;
  box-shadow: 0 16px 80px rgba(17, 0, 102, 0.16);
  border-radius: 10px;
  color: #636d72;
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 30px;
  position: relative;
  a {
    display: block;
    margin-bottom: 15px;
  }
  p {
    margin-top: 0;
  }
  h4 {
    text-align: center;
  }
  img {
    width: 130px;
    border-radius: 15px;
    margin: -50px auto 0;
  }
  button {
    margin-top: 10px;
  }
  ${(props) =>
    props.isLoading &&
    css`
      &:before {
        content: 'Loading...';
        display: block;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
`;

export const Input = styled.input`
  font-size: 16px;
  box-sizing: border-box;
  margin: 0 0 15px;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid;
  border-radius: 4px;
  transition: all 0.3s;
  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  background: ${(prop) => (prop.hasError ? '#fff' : 'none')};
  border-color: ${(prop) => (prop.hasError ? '#f5222d' : '#d9d9d9')};
}
`;

export const Select = styled.select`
    font-size: 16px;
  box-sizing: border-box;
  margin: 0 0 15px;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid;
  border-radius: 4px;
  transition: all 0.3s;
  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  background: ${(prop) => (prop.hasError ? '#fff' : 'none')};
  border-color: ${(prop) => (prop.hasError ? '#f5222d' : '#d9d9d9')};
}
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // justify-content: center;
  align-items: center;
`;

export const TextArea = styled.textarea`
  font-size: 16px;
  box-sizing: border-box;
  margin: 0 0 15px;
  width: 100%;
  height: 120px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid;
  border-radius: 4px;
  transition: all 0.3s;
  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  background: ${(prop) => (prop.hasError ? '#fff' : 'none')};
  border-color: ${(prop) => (prop.hasError ? '#f5222d' : '#d9d9d9')};
}
`;

export const Message = styled.div`
  color: ${(props) => props.theme.colors.error};
  min-height: 22px;
  margin: -5px 0 5px;
  font-size: 14px;
`;

export const BackButton = styled.div`
  position: absolute;
  left: 50px;
  top: 30px;
  cursor: pointer;
  @media (max-width: 1200px) {
    left: 30px;
  }
  @media (max-width: 768px) {
    left: 10px;
    top: 55px;
  }
`;
