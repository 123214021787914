export const GET_LINKS = 'GET_LINKS'
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS'
export const GET_LINKS_FAIL = 'GET_LINKS_FAIL'
export const GET_LINK = 'GET_LINK'
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS'
export const GET_LINK_FAIL = 'GET_LINK_FAIL'
export const CREATE_LINK = 'CREATE_LINK'
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS'
export const CREATE_LINK_FAIL = 'CREATE_LINK_FAIL'
export const UPDATE_LINK = 'UPDATE_LINK'
export const UPDATE_LINK_SUCCESS = 'UPDATE_LINK_SUCCESS'
export const UPDATE_LINK_FAIL = 'UPDATE_LINK_FAIL'
export const DELETE_LINK = 'DELETE_LINK'
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS'
export const DELETE_LINK_FAIL = 'DELETE_LINK_FAIL'

export function getLinks () {
  return {
    type: GET_LINKS
  }
}

export function getLink (payload) {
  return {
    type: GET_LINK,
    payload
  }
}

export function createLink (payload) {
  return {
    type: CREATE_LINK,
    payload
  }
}

export function updateLink (payload) {
  return {
    type: UPDATE_LINK,
    payload
  }
}

export function deleteLink (payload) {
  return {
    type: DELETE_LINK,
    payload
  }
}
