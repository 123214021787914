import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { getNews } from './services';

function* getNewsSaga(action) {
  try {
    const response = yield call(getNews, action.payload);
    yield put({
      type: actions.GET_NEWS_SUCCESS,
      entities: response.data
    });
  } catch (error) {
    yield put({
      type: actions.GET_NEWS_FAIL,
      error: error.message
    });
  }
}

export function* newsSaga() {
  yield takeLatest(actions.GET_NEWS, getNewsSaga);
}
