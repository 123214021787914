import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  grid-area: sidebar;
  width: 120px;
  position: fixed;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 40px;
  justify-content: flex-start;
  background-color: white;
  // border-right: 1px solid #f2f4f6;
  background: white;
  transition: left 0.2s ease-in-out;
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
    ${({ isVisible }) => (isVisible ? `left: 0px;` : `left: -200px;`)}
`;

export const Logo = styled.img`
  width: 140px;
  border-bottom: 1px solid #f2f4f6;
  margin: 0 -40px 15px;
  padding: 0 30px 30px;
`;

export const Toggle = styled.div`
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 3;
  padding: 10px 10px 8px 11px;
  background: white;
  border-radius: 0 0 4px 0;

  @media (max-width: 767px) {
    display: block;
    ${({ isVisible }) => isVisible && css``}
  }
`;

export const Span = styled.span`
  display: block;
  width: 27px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #333333;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 1px;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  &:first-child {
    transform-origin: 0% 0%;
  }

  &:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      transform: rotate(-45deg) translate(-1px, -3px);

      &:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      &:nth-last-child(3) {
        transform: rotate(45deg) translate(0, -1px);
      }
    `}
`;

export const Link = styled(NavLink)`
  padding: 15px 0 15px 0;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  width: calc(100% + 40px);
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    fill: 98a9bc;
    transition: all 0.3s ease-out;
  }
  span {
    color: 98a9bc;
    font-family: pnova-semibold;
  }

  &:hover,
  &.active {
    color: #252631;
    background: rgba(0, 0, 0, 0.03);
    padding: 15px 0 15px 40px;
    width: calc(100% + 36px);
    margin-left: -40px;
    border-left: 4px solid;
    border-color: 98a9bc;
    svg {
      fill: #33333;
    }
    span {
      color: #33333;
    }
    // &:nth-child(1) {
    //   border-color: #00d2ff;
    // }
    // &:nth-child(2) {
    //   border-color: #11ffbd;
    // }
    &:nth-child(3) {
      border-color: #5c258d;
    }
    &:nth-child(4) {
      border-color: #24c6dc;
    }
    &:nth-child(5) {
      border-color: #fcbe32;
    }
    &:nth-child(6) {
      border-color: #432de0;
    }
    &:nth-child(7) {
      border-color: #e71d36;
    }
    &:nth-child(8) {
      border-color: #a593e0;
    }
    &:nth-child(9) {
      border-color: #bf209f;
    }
    &:nth-child(10) {
      border-color: #e71d36;
    }
    &:nth-child(11) {
      border-color: #004e66;
    }
  }
`;
