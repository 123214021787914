import axios from 'axios';
import { rootUrl } from '../../config/constants';

const headers = {
  'X-ListenAPI-Key': 'dc7a0385d8384c079996318b898ddf8a'
};

export const getPodcasts = payload => {
  let params = '';
  if (payload && payload.genre && payload.genre) {
    params = `genre_id=${payload.genre}`;
  }

  let url = `https://listen-api.listennotes.com/api/v2/best_podcasts?page=${payload.page}&${params}`;

  if (payload.page < 4 && payload.genre === 157) {
    url = `${rootUrl}/data/podcasts/${payload.page}.json`;
  }

  return axios.get(url, { headers });
};

export const getPodcastDetails = payload => {
  const url = `https://listen-api.listennotes.com/api/v2/podcasts/${payload.id}`;
  return axios.get(url, { headers });
};

export const searchPodcasts = payload => {
  const url = `https://listen-api.listennotes.com/api/v2/search?type='podcast'&q=${payload.query}`;
  return axios.get(url, { headers });
};

export const getMoreEpisodes = payload => {
  const url = `https://listen-api.listennotes.com/api/v2/podcasts/${payload.id}?next_episode_pub_date=${payload.next_episode_pub_date}`;

  return axios.get(url, { headers });
};
