import * as actions from './actions';

const defaultState = {
  entity: { podcasts: [] },
  single: {
    entity: { episodes: [] },
    isLoading: false,
    successful: false
  },
  isLoading: false,
  isLoadingMore: false,
  successful: false,
  error: null,
  search: {
    entities: [],
    isLoading: false,
    successful: false
  }
};

export default function podcasts(state = defaultState, action) {
  switch (action.type) {
    case actions.GET_PODCASTS:
      return {
        ...state,
        isLoading: true,
        successful: false
      };
    case actions.GET_PODCASTS_SUCCESS:
      return {
        ...state,
        entity: action.payload,
        isLoading: false,
        successful: true
      };
    case actions.GET_PODCASTS_FAIL:
      return {
        ...state,
        entity: action.payload,
        isLoading: false,
        successful: false
      };

    case actions.GET_MORE_PODCASTS:
      return {
        ...state,
        isLoadingMore: true
      };

    case actions.GET_MORE_PODCASTS_SUCCESS:
      return {
        ...state,
        entity: {
          ...action.payload,
          podcasts: [...state.entity.podcasts, ...action.payload.podcasts]
        },
        isLoading: false,
        isLoadingMore: false,
        successful: true
      };
    case actions.GET_MORE_PODCASTS_FAIL:
      return {
        ...state,
        isLoadingMore: false
      };

    case actions.GET_PODCAST_DETAILS:
      return {
        ...state,
        single: {
          ...state.single,
          isLoading: true,
          successful: false
        }
      };

    case actions.GET_PODCAST_DETAILS_SUCCESS:
      return {
        ...state,
        single: {
          ...state.single,
          entity: action.payload,
          isLoading: false,
          successful: true
        }
      };
    case actions.GET_PODCAST_DETAILS_FAIL:
      return {
        ...state,
        single: {
          ...state.single,
          isLoading: false,
          successful: false
        }
      };

    case actions.GET_MORE_EPISODES:
      return {
        ...state,
        single: {
          ...state.single,
          isLoadingMore: true
        }
      };

    case actions.GET_MORE_EPISODES_SUCCESS:
      return {
        ...state,
        single: {
          ...state.single,
          entity: {
            ...state.single.entity,
            episodes: [
              ...state.single.entity.episodes,
              ...action.payload.episodes
            ],
            next_episode_pub_date: action.payload.next_episode_pub_date
          },
          isLoadingMore: false
        }
      };
    case actions.GET_MORE_EPISODES_FAIL:
      return {
        ...state,
        single: {
          ...state.single,
          isLoading: false,
          successful: false
        }
      };

    case actions.SEARCH_PODCASTS:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
          successful: false
        }
      };

    case actions.SEARCH_PODCASTS_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          entities: action.payload,
          isLoading: false,
          successful: true
        }
      };
    case actions.SEARCH_PODCASTS_FAIL:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          successful: false
        }
      };

    default:
      return state;
  }
}
