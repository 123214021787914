import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import PageHeader from '../../components/PageHeader/PageHeader';
import * as Styled from '../../styles/shared';
import Button from '../../components/Button/Button';
import { getTool, updateTool } from '../../features/tools/actions';

const statusOptions = [
  { label: '', value: '' },
  { label: 'Featured', value: 'featured' },
];

const EditTool = ({
  entities,
  entity,
  isLoading,
  updateTool,
  match,
  location,
}) => {
  const id = match.params.id;
  // const tool = location.state.tool;
  const tool = location.state.tool;

  const [selectedCategory, setSelectedCategory] = useState(tool.category);
  /**
   * If the entity is already present in the list of entities in the store we use that data.
   * Else we fetch the data of a single entity and the single object.
   */
  // const tool = entities[id] ? entities[id] : entity;

  const { register, errors, handleSubmit, control } = useForm({
    defaultValues: { ...tool },
  });

  const options = [
    ...new Set(
      entities.map((item) => {
        return { value: item.category, label: item.category };
      })
    ),
  ];

  const onSubmit = (data) => {
    const newTool = { ...data, category: selectedCategory };

    updateTool({ id, ...newTool });
  };

  return (
    <>
      <PageHeader title='Edit project' />
      <Styled.CenteredContainer>
        <Styled.Card isLoading={isLoading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: 15 }}>
              <Controller
                as={Select}
                options={options}
                control={control}
                value={selectedCategory}
                placeholder={selectedCategory || 'Select type'}
                onChange={([selected]) => {
                  return setSelectedCategory(selected.value);
                }}
                defaultValue={selectedCategory}
              />
            </div>
            <Styled.Input
              type='text'
              name='name'
              placeholder='Name'
              ref={register({
                required: 'Please enter a name',
              })}
            />
            {errors.name && (
              <Styled.Message>{errors.name.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='description'
              placeholder='Description'
              ref={register({
                required: 'Please enter a description',
              })}
            />
            {errors.description && (
              <Styled.Message>{errors.description.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='pricing'
              placeholder='pricing'
              ref={register()}
            />
            {errors.pricing && (
              <Styled.Message>{errors.pricing.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='link'
              placeholder='link'
              ref={register()}
            />
            {errors.link && (
              <Styled.Message>{errors.link.message}</Styled.Message>
            )}
            <Styled.Input
              type='text'
              name='twitter'
              placeholder='twitter'
              ref={register()}
            />
            <Styled.Input
              type='text'
              name='logoUrl'
              placeholder='Logo URL'
              ref={register()}
            />
            <div>
              <Styled.Select name='status' ref={register}>
                {statusOptions.map(({ label, value }) => (
                  <option value={value}>{label}</option>
                ))}
              </Styled.Select>
            </div>
            <Button type='submit' value='Submit' title='Update' />
          </form>
        </Styled.Card>
      </Styled.CenteredContainer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.entity,
    entities: state.tools.entities,
    entity: state.tools.entity,
    isLoading: state.tools.isLoading,
  };
}

EditTool.getInitialProps = async function ({ store, query }) {
  const state = store.getState().tools;

  /**
   * Only fetch data if it's not already present in the list of entities
   */
  if (!state.entities[query.id]) {
    store.dispatch(getTool({ id: query.id }));
  }
};

export default connect(mapStateToProps, { updateTool })(EditTool);
