import React from 'react';
import { connect } from 'react-redux';
import PodcastModal from '../Podcast/PodcastModal';

const MODAL_COMPONENTS = {
  PODCAST_MODAL: PodcastModal
};

const ModalContainer = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = state => {
  return {
    modalType: state.modals.modalType,
    modalProps: state.modals.modalProps
  };
};

export default connect(mapStateToProps)(ModalContainer);
