import { auth, db, now } from '../../config/firebase';

export const getJournalists = () => {
  return db.collection('journalists').get();
};

export const getJournalist = (payload) => {
  return db.collection('journalists').doc(payload.id).get();
};

export const createJournalist = (payload) => {
  payload.createdAt = now;
  payload.updatedAt = now;
  payload.userId = auth.currentUser.uid;
  return db.collection('journalists').doc(payload.id).set(payload);
};

export const updateJournalist = (payload) => {
  payload.updatedAt = now;
  return db.collection('journalists').doc(payload.id).update(payload);
};

export const deleteJournalist = (payload) => {
  return db.collection('journalists').doc(payload.id).delete();
};
