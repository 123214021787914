import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { messageTypes } from '../../config/constants';
import * as actions from './actions';
import * as services from './services';

function* getInvestorsSaga() {
  try {
    const querySnapshot = yield call(services.getInvestors);
    const entities = [];
    querySnapshot.forEach(function (doc) {
      entities.push(doc.data());
    });
    yield put({
      type: actions.GET_INVESTORS_SUCCESS,
      entities,
    });
  } catch (error) {
    yield put({
      type: actions.GET_INVESTORS_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* createInvestorSaga(action) {
  try {
    const doc = yield call(services.createInvestor, action.payload);
    const entity = { id: action.payload.id, ...action.payload };
    yield put({
      type: actions.CREATE_INVESTOR_SUCCESS,
      entity,
      message: { type: messageTypes.SUCCESS, text: 'Successfully created!' },
    });
    yield put(push('/investors'));
  } catch (error) {
    yield put({
      type: actions.CREATE_INVESTOR_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* updateInvestorSaga(action) {
  try {
    yield call(services.updateInvestor, action.payload);
    yield put({
      type: actions.UPDATE_INVESTOR_SUCCESS,
      entity: action.payload,
      message: { type: messageTypes.SUCCESS, text: 'Successfully updated!' },
    });
    yield put(push('/investors'));
  } catch (error) {
    yield put({
      type: actions.UPDATE_INVESTOR_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

function* deleteInvestorSaga(action) {
  try {
    yield call(services.deleteInvestor, action.payload);
    yield put(push('/investors'));
    yield put({
      type: actions.DELETE_INVESTOR_SUCCESS,
      id: action.payload.id,
      message: { type: messageTypes.SUCCESS, text: 'Successfully deleted!' },
    });
  } catch (error) {
    yield put({
      type: actions.DELETE_INVESTOR_FAIL,
      message: { type: messageTypes.ERROR, text: error.message },
    });
  }
}

export function* investorsSaga() {
  yield takeLatest(actions.GET_INVESTORS, getInvestorsSaga);
  yield takeLatest(actions.CREATE_INVESTOR, createInvestorSaga);
  yield takeLatest(actions.UPDATE_INVESTOR, updateInvestorSaga);
  yield takeLatest(actions.DELETE_INVESTOR, deleteInvestorSaga);
}
