export const GET_PODCASTS = 'GET_PODCASTS';
export const GET_PODCASTS_SUCCESS = 'GET_PODCASTS_SUCCESS';
export const GET_PODCASTS_FAIL = 'GET_PODCASTS_FAIL';

export const GET_MORE_PODCASTS = 'GET_MORE_PODCASTS';
export const GET_MORE_PODCASTS_SUCCESS = 'GET_MORE_PODCASTS_SUCCESS';
export const GET_MORE_PODCASTS_FAIL = 'GET_MORE_PODCASTS_FAIL';

export const GET_PODCAST_DETAILS = 'GET_PODCAST_DETAILS';
export const GET_PODCAST_DETAILS_SUCCESS = 'GET_PODCAST_DETAILS_SUCCESS';
export const GET_PODCAST_DETAILS_FAIL = 'GET_PODCAST_DETAILS_FAIL';

export const SEARCH_PODCASTS = 'SEARCH_PODCASTS';
export const SEARCH_PODCASTS_SUCCESS = 'SEARCH_PODCASTS_SUCCESS';
export const SEARCH_PODCASTS_FAIL = 'SEARCH_PODCASTS_FAIL';

export const GET_MORE_EPISODES = 'GET_MORE_EPISODES';
export const GET_MORE_EPISODES_SUCCESS = 'GET_MORE_EPISODES_SUCCESS';
export const GET_MORE_EPISODES_FAIL = 'GET_MORE_EPISODES_FAIL';

export function fetchPodcasts(payload) {
  return {
    type: payload.page > 1 ? GET_MORE_PODCASTS : GET_PODCASTS,
    payload
  };
}

export function fetchPodcastDetails(podcastId) {
  return {
    type: GET_PODCAST_DETAILS,
    payload: podcastId
  };
}

export function searchPodcasts(payload) {
  return {
    type: SEARCH_PODCASTS,
    payload
  };
}

export function fetchMoreEpisodes(payload) {
  return {
    type: GET_MORE_EPISODES,
    payload
  };
}
