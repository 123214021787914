import axios from 'axios';

import { rootUrl } from '../../config/constants';

export const getStrategies = payload => {
  const url = `${rootUrl}/data/strategies/${
    payload.category === 'startup' ? payload.category : 'links'
  }.json`;
  return axios.get(url);
};
