import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { getStrategies } from './services';

function* getStrategiesSaga(action) {
  try {
    const response = yield call(getStrategies, action.payload);
    yield put({
      type: actions.GET_STRATEGIES_SUCCESS,
      entities: response.data
    });
  } catch (error) {
    yield put({
      type: actions.GET_STRATEGIES_FAIL,
      error: error.message
    });
  }
}

export function* strategiesSaga() {
  yield takeLatest(actions.GET_STRATEGIES, getStrategiesSaga);
}
